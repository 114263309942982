import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner";
import Navbar from "../components/Navbar.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function AccountType(props) {
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="innerpages_wrapper">
        <div className="container">
          <div className="inner_title_wrapper">
            <h2 className="inner_title">Account Types</h2>
          </div>
          <div className="dash_wrapper">
            <ul
              class="nav nav-pills custom_nav_tabs justify-content-center"
              id="pills-tab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="pills-starting-acc-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-starting-acc"
                  type="button"
                  role="tab"
                  aria-controls="pills-starting-acc"
                  aria-selected="true"
                >
                  Just Starting
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="pills-vip-acc-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-vip-acc"
                  type="button"
                  role="tab"
                  aria-controls="pills-vip-acc"
                  aria-selected="false"
                >
                  VIP
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-starting-acc"
                role="tabpanel"
                aria-labelledby="pills-starting-acc-tab"
              >
                <div className="row">
                  <div className="col-xl-12">
                    <div class="snip1517">
                      <div class="plan">
                        <div>
                          <header>
                            <h4 class="plan-title">Basic</h4>
                            <div class="plan-cost">
                              <span class="plan-price">$5,000</span>
                            </div>
                          </header>
                          <ul class="plan-features">
                            <li>Low/Medium tier trades</li>
                            <li>1 Protection contract</li>
                            <li>24/5 AI trading</li>
                            <li>Conservative AI optimization</li>
                            <li>Available leverage 5-10</li>
                            <li>Returns average per week 6-8%</li>
                            <li>24/7 Agent Support Line</li>
                          </ul>
                        </div>
                        <div class="plan-select">
                          <a href="#" className="primary_btn">
                            Select Basic
                          </a>
                        </div>
                      </div>
                      <div class="plan">
                        <div>
                          <header>
                            <h4 class="plan-title">Bronze</h4>
                            <div class="plan-cost">
                              <span class="plan-price">$25,000</span>
                            </div>
                          </header>
                          <ul class="plan-features">
                            <li>Medium tier trades</li>
                            <li>5 Protection contract</li>
                            <li>24/5 AI trading</li>
                            <li>Conservative AI optimization</li>
                            <li>Available leverage 5-20</li>
                            <li>Returns average per week 10-15%</li>
                            <li>24/7 Agent Support Line</li>
                            <li>Access to higher level trading education</li>
                          </ul>
                        </div>
                        <div class="plan-select">
                          <a href="#" className="primary_btn">
                            Select Bronze
                          </a>
                        </div>
                      </div>
                      <div class="plan">
                        <div>
                          <header>
                            <h4 class="plan-title">Silver</h4>
                            <div class="plan-cost">
                              <span class="plan-price">$50,000</span>
                            </div>
                          </header>
                          <ul class="plan-features">
                            <li>Medium/High tier trades</li>
                            <li>5 Protection contract per month</li>
                            <li>24/5 AI trading</li>
                            <li>Conservative AI optimization</li>
                            <li>Arbitrage trading</li>
                            <li>Available leverage 10-50</li>
                            <li>Returns average per week 16-20%</li>
                            <li>24/7 Agent Support Line</li>
                            <li>Access to higher level trading education</li>
                          </ul>
                        </div>
                        <div class="plan-select">
                          <a href="#" className="primary_btn">
                            Select Silver
                          </a>
                        </div>
                      </div>
                      <div class="plan">
                        <div>
                          <header>
                            <h4 class="plan-title">Super</h4>
                            <div class="plan-cost">
                              <span class="plan-price">$75,000</span>
                            </div>
                          </header>
                          <ul class="plan-features">
                            <li>High tier trades</li>
                            <li>7 Protection contract per month</li>
                            <li>24/5 AI trading</li>
                            <li>Conservative AI optimization PRO</li>
                            <li>Arbitrage trading</li>
                            <li>Available leverage 25-75</li>
                            <li>Returns average per week 20-25%</li>
                            <li>24/7 Agent Support Line</li>
                            <li>Access to higher level trading education</li>
                            <li>Personal Accountant</li>
                          </ul>
                        </div>
                        <div class="plan-select">
                          <a href="#" className="primary_btn">
                            Select Diamond
                          </a>
                        </div>
                      </div>
                      <div class="plan">
                        <div>
                          <header>
                            <h4 class="plan-title">Platinum</h4>
                            <div class="plan-cost">
                              <span class="plan-price">$100,000</span>
                            </div>
                          </header>
                          <ul class="plan-features">
                            <li>High tier trades</li>
                            <li>10 Protection contract per month</li>
                            <li>24/5 AI trading</li>
                            <li>Conservative AI optimization PRO</li>
                            <li>Arbitrage trading</li>
                            <li>Available leverage 25-75</li>
                            <li>Returns average per week 20-25%</li>
                            <li>24/7 Agent Support Line</li>
                            <li>Access to higher level trading education</li>
                            <li>Personal Accountant</li>
                            <li>Access to alt coin trading</li>
                          </ul>
                        </div>
                        <div class="plan-select">
                          <a href="#" className="primary_btn">
                            Select Platinum
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-vip-acc"
                role="tabpanel"
                aria-labelledby="pills-vip-acc-tab"
              >
                <div className="row">
                  <div className="col-xl-12">
                    <div class="snip1517 justify-content-center">
                      <div class="plan">
                        <div>
                          <header>
                            <h4 class="plan-title">VIP</h4>
                            <div class="plan-cost">
                              <span class="plan-price">$200,000</span>
                            </div>
                          </header>
                          <ul class="plan-features">
                            <li>Top tier trades</li>
                            <li>Premium Signals</li>
                            <li>Unlimited protection contract per month</li>
                            <li>24/5 AI trading</li>
                            <li>Conservative AI optimization PRO</li>
                            <li>Arbitrage trading</li>
                            <li>Hedge fund investment opportunities</li>
                            <li>Available leverage 50-100</li>
                            <li>Returns average per week 50-60%</li>
                            <li>24/7 Agent Support Line</li>
                            <li>Access to PRO level trading education</li>
                            <li>Personal Accountant</li>
                            <li>Optimized alt coin trading</li>
                            <li>Access to alt coin trading</li>
                            <li>Priority Queuing</li>
                          </ul>
                        </div>
                        <div class="plan-select">
                          <a href="#" className="primary_btn">
                            Select VIP
                          </a>
                        </div>
                      </div>
                      <div class="plan">
                        <div>
                          <header>
                            <h4 class="plan-title">Hedge Fund Account</h4>
                            <div class="plan-cost">
                              <span class="plan-price mt-5">
                                Contact your account manager
                              </span>
                            </div>
                          </header>
                        </div>
                        <div class="plan-select">
                          <a href="#" className="primary_btn">
                            Select Hedge fund account
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
