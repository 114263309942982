
import axios, { handleResp } from '../config/axios'


import {
    SET_USER_KYC

} from '../constant';




export const getKycDetails = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/userKyc`,
        });

        dispatch(setUserKyc(respData.data.result))
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors: err.response.data.errors
        }
    }
}








export const updateIdProof = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/user/userKyc`,
            data
        });

        dispatch(setUserKyc(respData.data.result))
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors: err.response.data.errors
        }
    }
}



export const updateFundsProof = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/api/user/userKyc`,
            data
        });
        dispatch(setUserKyc(respData.data.result))

        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors: err.response.data.errors
        }
    }
}


export const setUserKyc = (data) => {
    return {
        type: SET_USER_KYC,
        data
    }
}