import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import Kyc from "../components/Kyc/kyc"
const KycUpload = () => {


    // Scroll to Top
    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);
        return null;
    }

    return (
        <>
            <div>
                <ScrollToTopOnMount />
                <NavbarInner />
                <div className="innerpages_wrapper">
                    <div className="container-fluid">
                        <Kyc />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}


export default KycUpload;