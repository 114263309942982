// import lib
import isEmpty from '../../lib/isEmpty';

export const validation = value => {
    let errors = {};
    let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG|pdf|PDF)$/;

    if (isEmpty(value.type)) {
        errors.type = "Select document type";
    }


    if (value.frontImage && value.frontImage.size) {
        if (value.frontImage.size > 1000000) {  // 10 MB
            errors.frontImage = "Too large"
        } else if (!imageFormat.test(value.frontImage.name)) {
            errors.frontImage = "Invalid image"
        }
    } else {
        errors.frontImage = "Front image field is required";
    }

    if (!isEmpty(value.type) && value.type != 'passport') {
        if (isEmpty(value.backImage.name)) {
            errors.backImage = "Back image field is required";
        } else if (value.backImage && value.backImage.size) {
            if (value.backImage.size > 1000000) {   // 10 MB
                errors.backImage = "Too large"
            } else if (!imageFormat.test(value.backImage.name)) {
                errors.backImage = "Invalid image"
            }
        }
        //  else {
        //     errors.backImage = "Back image field is required";
        // }
    }

    return errors;
}

export default validation;