import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import lib
import { dateTimeFormat } from '../../lib/dateTimeHelper';

//import modal
import ProfileModal from "./ProfileModal"

const AccountSettings = () => {

  const navigate = useNavigate()
  // redux-state
  const { firstName, lastName, loginHistory, profile } = useSelector((state) => state.account);


  //state
  const [Modal, setModal] = useState(false)


  //function
  const handleCloseModal = () => {
    setModal(false)
  }

  return (
    <div className="dash_wrapper">
      <ProfileModal isShow={Modal} onHide={handleCloseModal} />
      <div className="row">
        <div className="col-lg-12">
          <div className="dash_box">
            <div class="user_info">
              <img
                alt="arrow"
                class="img-fluid"
                src={profile ? profile : require("../../assets/images/copy_usericon.png")}
              />
              <div class="user_info_content">
                <h3>{firstName}{" "}{lastName}</h3>
                <h5>
                  {(loginHistory && loginHistory.createdAt) && <span>Last Login: {dateTimeFormat(loginHistory.createdAt)}</span>}
                </h5>
                <h6>
                  <span
                    onClick={() => setModal(true)}
                  >
                    Edit Profile
                  </span>{" "}
                  <span className="center_line">|</span>{" "}
                  <span
                    data-bs-toggle="modal"
                    data-bs-target="#changepassword_modal"
                  >
                    Change Password
                  </span>
                  <span className="center_line">|</span>{" "}
                  <span onClick={() => navigate("/kyc")}
                  >
                    Account Verification
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountSettings;