import React, { useContext, useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";

import { toFixedDown, truncateDecimals } from "../../lib/roundOf";

import DataTable from "react-data-table-component";
import SocketContext from "../../context/SocketContext";



const ListTable = (props) => {


    const [tablecontent, setContent] = useState("first")

    const { duppairlists, setduppairlists } = props

    const socketContext = useContext(SocketContext);


    const [pageDoc, setPageDoc] = useState({ page: 1, limit: 30 });
    const { page, limit } = pageDoc;




    const columns = [
        {
            name: "Name",
            selector: (row) => <div className="market_pair">
                <img
                    alt="img"
                    className="img-fluid"
                    src={row?.image}
                />
                <p>
                    {row?.firstCurrencySymbol
                        + "/" + row?.secondCurrencySymbol} <span>{row?.currencyName}</span>
                </p>
            </div>,
        },
        {
            name: "Total",
            selector: (row) => <> $
                <CurrencyFormat
                    displayType={"text"}
                    thousandSeparator={true}
                    value={toFixedDown(row?.hedgeQty * row?.markPrice, 2)}
                />
            </>,
        },
        {
            name: "Quantity",
            selector: (row) => <>
                <CurrencyFormat
                    displayType={"text"}
                    thousandSeparator={true}
                    value={toFixedDown(row?.hedgeQty, 8)}
                />

            </>,
        },
        {
            name: "Price",
            selector: (row) => <> $  <CurrencyFormat
                displayType={"text"}
                thousandSeparator={true}
                value={toFixedDown(row?.markPrice, 8)}
            />
            </>,
        },
        {
            name: "24H Change",
            selector: (row) => <span className={row?.change < 0 ? "change_badge badge_red" : "change_badge badge_green"}>{row?.change}%</span>
        },
        {
            name: "24H High",
            selector: (row) => <> $
                <CurrencyFormat
                    displayType={"text"}
                    thousandSeparator={true}
                    value={toFixedDown(row?.high, 8)}
                />

            </>,
        },
        {
            name: "24H Low",
            selector: (row) => <> $    <CurrencyFormat
                displayType={"text"}
                thousandSeparator={true}
                value={toFixedDown(row?.low, 8)}
            />
            </>,
        },

    ];



    useEffect(() => {
        if (tablecontent == "first") {
            socketContext.socket.on("marketPrice", (result) => {
                // console.log(result,"-------result")
                setduppairlists((el) => {
                    let pairList = [];
                    el.map((item) => {
                        if (item._id == result.pairId) {
                            pairList.push({
                                ...item,
                                ...{
                                    markPrice: result.data.markPrice,
                                    change: result.data.change,
                                },
                            });
                        } else {
                            pairList.push(item);
                        }
                    });

                    return pairList;
                });
            });
        }
    }, []);


    return (
        <>

            <div className="primary_datatable">
                <DataTable columns={columns} data={duppairlists}
                    pagination
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[30, 40, 50, 60]}
                    onChangePage={(page) =>
                        setPageDoc({ ...pageDoc, ...{ page: page } })
                    }
                    onChangeRowsPerPage={(limit) =>
                        setPageDoc({ ...pageDoc, ...{ page: 1, limit: limit } })
                    } />
            </div>
        </>
    )




}


export default ListTable;