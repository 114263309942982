import React, { useState, useEffect, useContext } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Slider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CurrencyFormat from 'react-currency-format';
import SocketContext from "../../context/SocketContext";

//import api
import { orderPlace, updateTradeAsset } from "../../api/tradeAction"

//import lib
import { encryptObject } from "../../lib/cryptoJS"
import { toFixedDown, truncateDecimals } from "../../lib/roundOf"
import { toastAlert } from "../../lib/toastAlert";
import isEmpty from '../../lib/isEmpty';

//import validation
import { OrderValidate } from "./validation"





function valuetext(value) {
    return `${value}%`;
}

const useStyles = makeStyles({
    markLabel: {
        color: "#ffffff",
    },
    mark: {
        backgroundColor: "#ffffff",
    },
});





const generateMarks = (max) => {
    const marks = [{ value: 0, label: 0 }];
    for (let i = 10; i <= max; i += 10) {
        marks.push({ value: i, label: `${i}` });
    }
    return marks;
};


const initialFormValue = {
    quantity: 0,
    price: 0,
    leverage: 0,
}

const Orderplace = (props) => {






    //props
    const { type, pairData } = props

    //state
    const [formValue, setFormValue] = useState(initialFormValue);
    const [loader, setLoader] = useState(false);
    const [isLeverageChanged, setIsLeverageChanged] = useState(false); // New state for leverage change tracking

    const { quantity, price, leverage } = formValue;

    const socketContext = useContext(SocketContext);
    const dispatch = useDispatch()

    const classes = useStyles();



    const marks = generateMarks(pairData?.leverage);


    //redux
    const { firstCurrency, secondCurrency } = useSelector(
        (state) => state.tradeAsset
    );
    const priceConversion = useSelector((state) => state.priceConversion);
    const tradePair = useSelector((state) => state.tradePair);
    const marketPrice = useSelector((state) => state.marketPrice)

    //function
    const handleTotal = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        // if (name == "quantity") {
        //     if (/[^0-9.]/.test(value)) return;
        // }
        let formData = { ...formValue, ...{ [name]: toFixedDown(value, tradePair?.secondFloatDigit) } };
        setFormValue(formData);
        if (value) {
            setLoader(false)
        }
    };

    const handleMax = () => {
        let total = secondCurrency?.stakeBal - secondCurrency?.lockedBal
        let Markprice = type === "buy" ? marketPrice?.askPrice : marketPrice?.bidPrice;
        let price = total / Markprice * tradePair?.leverage
        let formData = { ...formValue, ...{ ["quantity"]: toFixedDown(price, tradePair?.secondFloatDigit) } };
        setFormValue(formData);
    }


    const handleClick = (value) => {
        try {
            let amount = quantity
            if (value === 'sub' && amount > 0) {
                amount--
            } else if (value === 'add') {
                amount++
            }
            let formData = { ...formValue, ...{ ["quantity"]: toFixedDown(amount, tradePair.secondFloatDigit) } };
            setFormValue(formData);
        } catch (err) {
            console.log(err, 'errror')
        }
    }

    const handleLeverageChange = (event, newValue) => {
        setFormValue({ ...formValue, leverage: newValue });
        setIsLeverageChanged(true)
    };

    const handleSubmit = async () => {
        try {
            let reqData = {
                price: price,
                quantity: quantity,
                buyorsell: type,
                pairId: tradePair.pairId,
                newdate: new Date(),
                action: "open",
                leverage: leverage,

            };
            const validateError = await OrderValidate(reqData);
            if (!isEmpty(validateError)) {
                setLoader(false);
                toastAlert("error", validateError[Object.keys(validateError)[0]], "limitOrder");
                return;
            }

            let encryptToken = {
                token: encryptObject(reqData),
            };
            setLoader(true);
            let { status, message, error } = await orderPlace(
                encryptToken
            )
            setTimeout(() => {
                setLoader(false);
            }, 500)
            if (status == "success") {
                toastAlert("success", message, "spotOrder");
            } else {
                setLoader(false);
                if (!isEmpty(error)) {
                    toastAlert("error", error[Object.keys(error)[0]], "limitOrder");
                    return;
                }
                toastAlert("error", message, "spotOrder");
            }
        } catch (err) {
            setLoader(false);
            console.log(err, 'errror')
        }
    };




    useEffect(() => {

        if (!isLeverageChanged && pairData?.leverage) {
            setFormValue({ ...formValue, ...{ ["leverage"]: pairData?.leverage } })
        }
    }, [type, pairData, quantity, priceConversion, marketPrice])



    useEffect(() => {
        // socket
        socketContext.socket.on("updateTradeAsset", (result) => {
            updateTradeAsset(dispatch, result);
        });
    }, []);

    // console.log(loader, 'marketPricemarketPrice')
    let Balance = secondCurrency.stakeBal - secondCurrency.lockedBal
    let margin = marketPrice?.markPrice * (quantity / leverage)
    return (
        <>

            <div class="form-group">
                <div className="d-flex justify-content-between gap-10 flex-wrap">
                    <label for="amount" class="form-label">
                        Quantity
                    </label>
                    <label class="form-label">Available Balance: <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(Balance, tradePair.secondFloatDigit)} /> {pairData.secondCurrencySymbol}</label>
                </div>
                <div className="input-group">
                    <button type="button" onClick={() => handleClick("sub")}
                        class="primary_asset_inp"
                    >-</button>
                    <input
                        type="number"
                        className="form-control"
                        name="quantity"
                        value={quantity}
                        onChange={handleTotal}
                        placeholder="Enter Quantity"
                    />
                    <button type="button" onClick={() => handleClick("add")}
                        class="primary_asset_inp"
                    >+</button>
                </div>
                <div className="d-flex justify-content-between gap-10 flex-wrap">
                    <label for="amount" class="form-label">
                    </label>
                    <label className="max_label"
                        onClick={handleMax}
                    >Max</label>
                </div>
            </div>

            <div className="form-group">
                <div className="rcslider_custom">
                    <label>Leverage</label>: <span>{leverage}x</span>
                    <Slider
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-custom"
                        step={1}
                        valueLabelDisplay="auto"
                        value={leverage}
                        color={"#00b881"}
                        marks={marks}
                        max={pairData?.leverage}
                        onChange={handleLeverageChange}
                        classes={{
                            markLabel: classes.markLabel,
                            mark: classes.mark,
                        }}
                    />
                </div>
            </div>
            <div className="form-group">
                <div className="d-flex justify-content-between">
                    <label>Trade Size:</label> <span>{quantity}</span>{" "}
                </div>
                <div className="d-flex justify-content-between">
                    <label>Pip Value:</label> <span>{toFixedDown(quantity / 100, 2)}</span>{" "}
                </div>
                <div className="d-flex justify-content-between">
                    <label>Used Margin:</label> <span> <CurrencyFormat displayType={'text'} thousandSeparator={true} value={truncateDecimals(margin, 2)} /></span>
                </div>
            </div>
            <div>
                {loader ?
                    <button class={type === 'buy' ? "primary_btn w-100" : "primary_btn w-100 primary_sell_btn"} disabled={true} >Loading ...</button> :
                    <button onClick={handleSubmit} class={type === 'buy' ? "primary_btn w-100" : "primary_btn w-100 primary_sell_btn"}>{type.toUpperCase()}</button>
                }
            </div>
        </>
    )
}


export default Orderplace;